//	--------------------
//	Colors:
//	--------------------

$color__default--black: #000000;
$color__default--white: #FFFFFF;

//	--------------------
//	Brand colors:
//	--------------------

$color__brand--first: #808080;	//	logo gray
$color__brand--second: #9C1006;	//	logo red
$color__brand--third: #333333;	//	button gray
$color__brand--fourth: #666666;	//	quote gray
$color__brand--fifth: #7f7f7f;	//	footer logo gray