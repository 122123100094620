//	--------------------
//	Homepage - Jobs:
//	--------------------

.jobs {
	&__container {
		padding: 165px 0 315px 150px;
		position: relative;
		border-left: 1px solid $color__default--black;
		&::before {
			content: "Jobs";
			position: absolute;
			top: calc(35% + 41px);
			left: -43px;
			font: {
				size: $font__info-text--size;
				weight: $font__info-text--weight;
			}
			text-transform: uppercase;
			transform: rotate(-90deg);
		}
		@include media("<=1280px") {
			padding-bottom: 125px;
		}
		@include media("<=1075px") {
			padding-left: 110px;
		}
		@include media("<=md") {
			padding: 0 30px 110px;
			border-left: none;
		}
	}
	&__content {
		// padding: 110px 145px 75px 100px;
		padding: 110px 455px 75px 100px;
		background: $color__brand--second;
		position: relative;
		width: 1350px;
		&::after {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			bottom: -120px;
			left: 102px;
			border: {
				style: solid;
				width: 120px 110px 0 0;
				color: $color__brand--second transparent transparent transparent;
			}
		}
		@include media("<=1810px") {
			padding-right: 275px;
			width: 1190px;
		}
		@include media("<=1640px") {
			padding-right: 150px;
			width: 1040px;
		}
		@include media("<=1490px") {
			padding-right: 100px;
			width: 100%;
		}
		@include media("<=lg") {
			padding: 75px 60px;
		}
		@include media("<=md") {
			padding: 60px;
		}
		@include media("<=sm") {
			padding: 60px 30px;
		}
		@include media("<=480px") {
			text-align: center;
		}
	}
	&__heading {
		color: $color__default--white;
		font: {
			size: 73px;
			weight: 300;
		}
		text-transform: uppercase;
		line-height: 90px;
		@include media("<=1075px") {
			font-size: 54px;
		}
		@include media("<=lg") {
			font-size: 48px;
			line-height: 72px;
		}
		@include media("<=800px") {
			font-size: 42px;
			line-height: 60px;
		}
		@include media("<=320px") {
			font-size: 36px;
			line-height: 42px;
		}
	}
	&__highlight {
		color: $color__default--black;
	}
	&__spacer {
		display: inline-block;
		width: 335px;
	}
	&__button {
		text-align: right;
		@include media("<=480px") {
			margin-top: 30px;
			text-align: center;
		}
		& .button {
			padding: 23px 42px;
			margin-top: 5px;
		}
	}
}