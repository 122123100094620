//	--------------------
//	SingleProject - Description:
//	--------------------

.description {
	position: relative;
	&__logo {
		@include media(">=1600px") {
			&::after {
				content: brand("logo.svg");
				width: 590px;
				height: auto;
				position: absolute;
				left: 0;
				top: calc(40% + 80px);
				-webkit-transform: rotate(90deg);
				transform: rotate(90deg);
				-webkit-transform-origin: 123px 0;
				transform-origin: 123px 0;
			}
		}
	}
	&__container {
		padding: 0 35px 180px 110px;
		margin-bottom: 230px;
		background: {
			image: pic("single-project-description-bg.jpg");
			repeat: no-repeat;
			position: left bottom;
			position-x: -175px;
		}
		position: relative;
		border-left: 1px solid $color__default--black;
		&::before {
			content: "Projekte";
			position: absolute;
			top: calc(15% + 80px);
			left: -57px;
			font-size: 12px;
			font-weight: 700;
			text-transform: uppercase;
			transform: rotate(-90deg);
			@include media("<=md") {
				content: none;
			}
		}
		&::after {
			content: "Bekommen wir gerne";
			padding-left: 70px;
			max-height: 16px;
			position: absolute;
			left: -104px;
			bottom: 90px;
			background: {
				color: $color__default--white;
				image: icon("envelope.svg");
				repeat: no-repeat;
				position: 30px 0;
			}
			font: {
				size: $font__info-text--size;
				weight: $font__info-text--weight;
			}
			line-height: $font__info-text--lh;
			text-transform: uppercase;
			transform: rotate(90deg);
			@include media("<=md") {
				content: none;
			}
		}
		@include media("<=1280px") {
			padding-bottom: 30px;
			padding-left: 80px;
		}
		@include media("<=md") {
			border-left:none;
		}
		@include media("<=640px") {
			padding: 0 30px 30px;
			margin-bottom: 30px;
		}
	}
	&__content {
		position: relative;
		&::after {
			content: "";
			display: block;
			clear: both;
		}
	}
	&__box {
		width: 48%;
		float: left;
		&--default {
			width: 48%;
		}
		&--gutter {
			width: 4%;	
		}
		@include media("<=600px") {
			width: 100%;
		}
	}
	&__heading {
		margin: 86px 0 55px;
		font: {
			size: 38px;
			weight: 300;
		}
		line-height: 48px;
		text-transform: uppercase;
		@include media("<=md") {
			& br {
				display: none;
			}
		}
		@include media("<=600px") {
			margin-top: 45px;
		}
	}
	&__photo {
		position: relative;
		left: -35px;
		&--first {
			margin-top: 240px;
			@include media("<=600px") {
				margin-top: 30px;
			}
		}
		&--second {
			margin-top: 138px;
			left: 0;
			@include media("<=xl") {
				margin-top: 45px;
			}
			@include media("<=600px") {
				margin-top: 30px;
			}
		}
		&--third {
			margin-top: 35px;
			@include media("<=600px") {
				margin-top: 30px;
			}
		}
		@include media("<=1280px") {
			left: 0;
			width: 100%;
		}
	}
	&__text {
		padding: 0 100px 0 0;
		position: relative;
		&--first {
			padding-top: 115px;
			left: -35px;
			@include media("<=1280px") {
				padding-top: 45px;
				left: 0;
			}
			@include media("<=600px") {
				padding: 0;
			}
		}
		&--last {
			padding-top: 30px;
			left: -35px;
			@include media("<=1280px") {
				left: 0;
			}
		}
		@include media("<=1280px") {
			padding-right: 15px;
		}
		@include media("<=sm") {
			padding-right: 0;
		}
	}
	&__quote {
		padding: 28px 25px 150px;
		padding-top: 28px;
		padding-right: 25px;
		color: #666666;
		font: {
			size: 54px;
			weight: 600;
		}
		line-height: 66px;
		text-align: right;
		@include media("<=lg") {
			font-size: 42px;
			line-height: 48px;
		}
		@include media("<=600px") {
			padding: 30px;
			font-size: 32px;
			& br {
				display: none;
			}
		}
	}
	&__author {
		padding-top: 34px;
		color: $color__brand--second;
		font: {
			size: 18px;
		}
		line-height: 24px;
		display: block;
	}
}