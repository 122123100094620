//	--------------------
//	Steps - Creation:
//	--------------------

.creation {
	&__container {
		padding: 80px 0 93px 485px;
		position: relative;
		border-left: 1px solid $color__default--black;
		&::before {
			content: "Prozesse";
			position: absolute;
			top: 84px;
			left: -56px;
			font: {
				size: $font__info-text--size;
				weight: $font__info-text--weight;
			}
			text-transform: uppercase;
			transform: rotate(-90deg);
		}
		@include media("<=1600px") {
			padding-top: 60px;
		}
		@include media("<=1280px") {
			padding-top: 60px;
			padding-left: 80px;
		}
		@include media("<=1200px") {
			padding-top: 40px;
		}
		@include media("<=800px") {
			padding-bottom: 60px;
		}
		@include media("<=md") {
			padding: 30px;
			border-left: none;
		}
	}
	&__content {
		padding-left: 130px;
		padding-bottom: 130px;
		border-left: 3px dotted $color__default--black;
		&::before {
			padding: 22px;
			width: 104px;
			height: 104px;
			position: absolute;
			top: 80px;
			left:  432px;
			background: $color__default--white;
			font: {
				size: 54px;
				weight: 600;
			}
			text-align: center;
			border: 7px solid $color__default--black;
			border-radius: 50%;
			z-index: 10;
			counter-increment: step;
			content: counter(step);
			@include media("<=1820px") {
				top: 25px;
			}
			@include media("<=1280px") {
				left: 120px;
			}
			@include media("<=800px") {
				content: none;
			}
		}
		@include media("<=1280px") {
			padding-left: 75px;
			padding-bottom: 90px;
		}
		@include media("<=1200px") {
			padding-bottom: 60px;
		}
		@include media("<=800px") {
			padding-left: 0;
			padding-bottom: 0;
			border-left: none;
		}
	}
	&__heading {
		margin-bottom: 60px;
		font: {
			size: 34px;
			weight: 300;
		}
		text-transform: uppercase;
		line-height: 44px;
		@include media("<=1280px") {
			padding-left: 90px;
			margin-bottom: 75px;
		}
		@include media("<=1200px") {
			margin-bottom: 45px;
		}
		@include media("<=800px") {
			padding-left: 0;
		}
		@include media("<=md") {
			text-align: center;
		}
		@include media("<=360px") {
			font-size: 28px;
			line-height: 30px;
		}
		@include media("<=360px") {
			font-size: 24px;
		}
	}
	&__text {
		
	}
}