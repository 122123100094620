//	--------------------
//	Homepage - News:
//	--------------------

.news {
	position: relative;
	@include media(">=1600px") {
		&::after {
			content: brand("logo.svg");
			width: 590px;
			height: auto;
			position: absolute;
			left: 0;
			top: calc(65% + 17px);
			transform: rotate(90deg);
			transform-origin: 123px 0;
		}
	}
	&__container {
		padding-left: 80px;
		position: relative;
		display: flex;
		border-left: 1px solid $color__default--black;
		&::before {
			content: "Brandneu";
			position: absolute;
			top: calc(15% + 42px);
			left: -59px;
			font: {
				size: $font__info-text--size;
				weight: $font__info-text--weight;
			}
			text-transform: uppercase;
			transform: rotate(-90deg);
		}
		@include media("<=1075px") {
			flex-wrap: wrap;
		}
		@include media("<=md") {
			padding: 0 30px;
			border-left: none
		}
	}
	&__heading {
		flex: 1 0 33.33%;
		color: #666666;
		font: {
			size: 73px;
			weight: 300;
		}
		line-height: 88px;
		text-transform: uppercase;
		@include media("<=1024px") {
			flex: 1 0 100%;
			font-size: 60px;
			line-height: 60px;
		}
		@include media("<=800px") {
			font-size: 48px;
			line-height: 48px;
		}
	}
	&__text {
		padding: 250px 75px 115px 25px;
		position: relative;
		display: inline-block;
		border: 8px solid #000000;
		&::before {
			content: "News";
			position: absolute;
			top: 160px;
			left: 25px;
			text-transform: uppercase;
		}
		&::after {
			content: "News";
			position: absolute;
			bottom: 25px;
			left: 25px;
			color: $color__brand--second;
			text-transform: uppercase;
		}
		@include media("<=1075px") {
			padding: 30px;
			margin-bottom: 35px;
			display: block;
			&::before,
			&::after {
				content: none;
			}
		}
	}
	&__content {
		flex: 1 0 66.66%;
		@include media("<=1024px") {
			flex: 1 0 100%;
		}
	}
	&__posts {
		margin-left: 35px;
		position: relative;
		&::after {
			content: "";
			display: block;
			clear: both;
		}
		@include media("<=800px") {
			margin-left: 0;
		}
	}
	&__post {
		margin-bottom: 35px;
		width: 48%;
		float: left;
		@include media("<=480px") {
			width: 100%;
		}
		&--default {
			width: 48%;
		}
		&--gutter {
			width: 4%;
		}
		&--alt {
			padding: 27px 32px 42px 60px;
			border: 2px solid #000000;
			@include media("<=800px") {
				padding: 25px 15px;
			}
		}
	}
	&__title {
		padding: 10px 0 18px;
		font: {
			size: 18px;
			weight: bold;
		}
		line-height: 18px;
		text-align: right;
		border-top: 2px solid #000000;
		&--alt {
			border: none;
		}
	}
	&__subtitle {
		margin: 100px 0 40px;
		font: {
			size: 34px;
			weight: 400;
		}
		text: {
			align: right;
			indent: 10px;
		}
		line-height: 40px;
	}
	&__description {
		padding: 88px 40px;
		min-height: 505px;
		color: $color__default--white;
		background: {
			image: pic("homepage-news-2.jpg");
			size: cover;
		}
	}
	&__info {
		font: {
			size: 18px;
			weight: 600;
		}
		text-align: right;
		line-height: 24px;
	}
	&__link {
		color: $color__default--white;
	}
	&__image {
		width: 100%;
		max-width: 100%;
	}
	&__button {
		& .button {
			width: 100%;
			text-align: center;
		}
	}
}