//	--------------------
//	Header:
//	--------------------

.header {
	@include media(">=1600px") {
		&::after {
			content: brand("logo.svg");
			width: 590px;
			height: auto;
			position: absolute;
			left: 0;
			top: 100%;
			transform: rotate(90deg);
			transform-origin: 123px 0;
		}
	}
	&__container {
		padding: 110px 0 62px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		@include media("<=800px") {
			padding: 60px 15px 45px;
		}
		@include media("<=360px") {
			
		}
	}
	&__logo {
		margin-top: -8px;
		// flex: 1 0 50%;
	}
	&__menu {
		margin-top: 10px;
		 -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
	&__nav {
		position: fixed;
		// position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1000;
		display: none;
		justify-content: center;
		align-items: center;
		flex: 1 0 100%;
		overflow-y: scroll;
		@include media("<=800px") {
			align-items: flex-start;
		}
		@include media("<=360px") {
			// padding-top: 300px;
		}
		
		&--open {
			display: flex;
		}
	}
}

.logo {
	&__text {
		width: 1px;
		height: 1px;
		position: absolute;
		overflow: hidden;
	}
	&__image {
		width: 212px;
		height: 44px;
	}
}

.burger {
	&-menu {
		&__bar {
			margin: 10px 0;
			width: 40px;
			height: 1px;
			background-color: $color__default--black;
			display: block;
		}
	}
	&-close {
		flex: 1 0 100%;
		margin-bottom: 100px;
		text-align: right;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		&__icon {
			width: 28px;
			height: 28px;
			position: relative;
			top: -19px;
			background: icon("icon-close.svg") no-repeat center center;
			display: inline-block;
			cursor: pointer;
			@include media("<=800px") {
				position: static;
			}
		}
		@include media("<=1375px") {
			margin-bottom: 0;
		}
		@include media("<=800px") {
			position: absolute;
			top: 15px;
			right: 15px;
		}
	}
}

.nav {
	background: rgba(255,255,255,1);
	&__container {
		margin: 0;
		max-height: 665px;
		@include media("<=1375px") {
			margin-top: 45px;
		}
		@include media("<=800px") {
			padding: 30px;
			// margin: 30px 0;
			margin: 0 auto;
			// max-height: 100vh;
			max-height: none;
			text-align: center;
			// position: fixed;
			// width: 100%;
			// height: 100%;
			
		}
		@include media("<=sm") {
			padding: 0;
		}
	}
	&__content {
		display: flex;
		flex-wrap: wrap;
	}
	&__box {
		flex: 1 0 50%;
		@include media("<=800px") {
			flex: 1 0 100%;
		}
	}
	&__title {
		margin-bottom: 30px;
		color: $color__brand--second;
		font-size: 13px;
		text-transform: uppercase;
		&::before {
			margin-right: 20px;
			content: "";
			width: 180px;
			height: 1px;
			background-color: $color__brand--second;
			display: inline-block;
		}
		@include media("<=800px") {
			margin-bottom: 0;
			&--menu {
				padding-top: 30px;
			}
		}
		@include media("<=320px") {
			&--menu {
				padding-top: 60px;
			}
		}
	}
	&__list {
		padding-left: 200px;
		text-transform: uppercase;
		@include media("<=1024px") {
			padding-left: 0;
		}
	}
	&__item {
		padding: 28px 0;
		font-size: 36px;
		@include media("<=1024px") {
			padding: 20px 0;
			font-size: 32px;
		}
		&--alt {
			padding: 0;
			font-size: 18px;
			@include media("<=1024px") {
				padding: 0;
				font-size: 18px;
			}
		}
	}
	&__link {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		&:hover {
			color: $color__brand--second;
		}
		&--fb {
			margin-top: 14px;
			margin-left: 205px;
			width: 40px;
			height: 40px;
			background-image: icon("fb.png");
			background-repeat: no-repeat;
			background-position: -27px center;
			display: inline-block;
			border: 1px solid $color__default--black;
			border-radius: 50%;
			@include media("<=lg") {
				margin-left: 0;
			}
			@include media("<=800px") {
				margin: 15px 0;
			}
		}
	}
	&__contact,
	&__social {
		padding-left: 90px;
		@include media("<=1024px") {
			padding-left: 0;
		}
	}
	&__address,
	&__links {
		padding-left: 205px;
		font-size: 18px;
		font-style: normal;
		line-height: 31px;
		@include media("<=1024px") {
			padding-left: 0;
		}
		@include media("<=lg") {
			padding-left: 50px;
		}
		@include media("<=lg") {
			padding-left: 0;
		}
	}
	&__address {
		padding-top: 15px;
		padding-bottom: 15px;
	}
	&__links {
		padding-top: 17px;
		padding-bottom: 60px;
		@include media("<=1024px") {
			padding-top: 0;
			padding-bottom: 15px;
		}
	}
}

.company-name {
	color: $color__brand--second;
	font-weight: 600;
}