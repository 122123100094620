//	--------------------
//	SingleProject - People:
//	--------------------

.people {
	background: #DEDEDE;
	&__container {
		padding: 40px 0 65px;
		margin-top: -30px;
		position: relative;
		&::after {
			content: "";
			width: 1px;
			height: 55px;
			background: $color__default--black;
			position: absolute;
			bottom: 0;
			left: 0;
			@include media("<=md") {
				content: none;
			}
		}
		@include media("<=1280px") {
			padding-top: 100px;
		}
		@include media("<=1024px") {
			padding-top: 15px;
		}
	}
	&__heading {
		padding-right: 45px;
		padding-bottom: 30px;
		position: absolute;
		top: 195px;
		right: 35px;
		font: {
			size: 70px;
			weight: 300;
		}
		line-height: 78px;
		text-transform: uppercase;
		border-bottom: 7px solid #000000;
		z-index: 1;
		@include media("<=1640px") {
			right: 0;
		}
		@include media("<=1280px") {
			top: 90px;
		}
		@include media("<=1024px") {
			padding: 30px;
			margin: 30px;
			position: static;
			font-size: 60px;
			line-height: 60px;
			display: block;
			text-align: center;
			& br {
				display: none;
			}
		}
		@include media("<=800px") {
			font-size: 48px;
			line-height: 48px;
		}
		@include media("<=360px") {
			padding: 30px 0;
		}
	}
	&__slider {
		max-width: 1000px;
		@include media("<=1440px") {
			max-width: 900px;
		}
		@include media("<=1300px") {
			max-width: 800px;
		}
		@include media("<=1230px") {
			max-width: 700px;
		}
		@include media("<=1100px") {
			max-width: 620px;
		}
		@include media("<=1075px") {
			max-width: 535px;
		}
		@include media("<=1024px") {
			max-width: 100%;
		}
		&::after {
			content: "";
			width: 840px;
			height: 475px;
			position: absolute;
			right: -190px;
			bottom: 0;
			background: #E5E5E5;
			@include media("<=1440px") {
				width: 630px;
				right: -100px;
			}
			@include media("<=1375px") {
				width: 600px;
				right: -79px;
			}
			@include media("<=1280px") {
				right: -55px;
			}
			@include media("<=1135px") {
				content: none;
			}
			
		}
		& .slider__body {
			left: -275px;
			z-index: 1;
			@include media("<=1640px") {
				top: 30px;
				left: -205px;
			}
			@include media("<=1440px") {
				left: -100px;
			}
			@include media("<=1280px") {
				top: 0;
				left: -60px;
			}
			@include media("<=1024px") {
				left: 0;
			}
		}
		& .slick-dots {
			width: 50%;
			right: 26px;
			bottom: -50px;
			left: auto;
			@include media("<=1024px") {
				width: 100%;
				right: auto;
			}
		}
	}
	&__content {
	}
	&__box {
		position: absolute;
		right: 100px;
		width: 305px;
		top: 455px;
		@include media("<=1640px") {
			right: 65px;
		}
		@include media("<=1280px") {
			top: 350px;
		}
		@include media("<=1024px") {
			position: static;
			width: auto;
			padding: 30px 0 15px 80px;
		}
		@include media("<=640px") {
			padding: 45px 30px 0;
		}
	}
	&__text {
		@include media("<=1640px") {
			// padding-top: 50px;
		}
		@include media("<=1075px") {
			// padding-left: 0;
		}
		@include media("<=md") {
			// padding-top: 30px;
		}
	}
}