//	--------------------
//	Steps - Planning:
//	--------------------

.planning {
	&__container {
		padding-top: 125px;
		padding-left: 80px;
		position: relative;
		border-left: 1px solid $color__default--black;
		&::before {
			content: "Prozesse";
			position: absolute;
			top: calc(80% + 7px);
			left: -56px;
			font: {
				size: $font__info-text--size;
				weight: $font__info-text--weight;
			}
			text-transform: uppercase;
			transform: rotate(-90deg);
		}
		&::after {
			content: "";
			width: 3px;
			height: 100%;
			position: absolute;
			bottom: 0;
			right: 0;
			border-left: 3px dotted #000000;
			@include media("<=800px") {
				content: none;
			}
		}
		@include media("<=lg") {
			padding-top: 60px;
		}
		@include media("<=md") {
			padding: 60px 30px 0;
		}
	}
	&__photo {
		position: relative;
		left: -400px;
		@include media("<=1550px") {
			max-width: 100%;
		}
		@include media("<=1024px") {
			left: -40px;
		}
		@include media("<=md") {
			left: 0;
		}
	}
	&__content {
		padding-bottom: 55px;
		display: flex;
		flex-wrap: wrap;
		position: relative;
		&::before {
			padding: 22px;
			width: 104px;
			height: 104px;
			position: absolute;
			top: 107px;
			right: -54px;
			background: $color__default--white;
			font: {
				size: 54px;
				weight: 600;
			}
			text-align: center;
			border: 7px solid #000000;
			border-radius: 50%;
			z-index: 10;
			counter-increment: step;
			content: counter(step);
			@include media("<=800px") {
				content: none;
			}
		}
		&::after {
			content: "";
			width: calc(100% - 135px);
			height: 3px;
			position: absolute;
			bottom: 0;
			right: 0;
			border-bottom: 3px dotted #000000;
			@include media("<=1280px") {
				width: 100%;
			}
			@include media("<=800px") {
				content: none;
			}
		}
		@include media("<=768px") {
			padding-bottom: 0;
		}
	}
	&__box {
		flex: 1 0 50%;
		&--left {
			flex: 1 0 40%;
			text-align: right;
			@include media("<=1024px") {
				flex: 1 0 100%;
			}
		}
		&--right {
			flex: 1 0 60%;
			@include media("<=1024px") {
				flex: 1 0 100%;
			}
		}
		@include media("<=sm") {
			flex: 1 0 100%;
		}
	}
	&__heading {
		margin: 92px 30px 0 0;
		font: {
			size: 34px;
			weight: 300;
		}
		line-height: 44px;
		text-align: left;
		text-transform: uppercase;
		display: inline-block;
		@include media("<=1024px") {
			display: block;
			& br {
				display: none;
			}
		}
		@include media("<=lg") {
			margin-top: 30px;
		}
		@include media("<=md") {
			margin-right: 0;
			text-align: center;
		}
		@include media("<=360px") {
			font-size: 28px;
			line-height: 30px;
		}
		@include media("<=360px") {
			font-size: 24px;
		}
	}
	&__text {
		padding: 102px 162px 0 62px;
		@include media("<=1024px") {
			padding: 30px 60px 30px 0;
		}
		@include media("<=800px") {
			padding-right: 0;
		}
	}
	&__image {
		padding: 0 63px;
		width: 100%;
		max-width: 100%;
		position: relative;
		top: -35px;
		left: 35px;
		display: block;
		@include media("<=1024px") {
			top: 0;
			left: 0;
		}
		@include media("<=lg") {
			padding: 0 30px;
		}
		@include media("<=sm") {
			padding: 0 90px 30px;
		}
		@include media("<=360px") {
			padding: 0 30px 30px;
		}
	}
	&__list {
		padding: 92px 82px;
		@include media("<=1024px") {
			padding: 0 90px 0 0;
		}
		@include media("<=800px") {
			padding: 0;
		}
	}
	&__title {
		margin-bottom: 5px;
		font: {
			size: 18px;
			weight: bold;
		}
		line-height: 18px;
		&--highlight {
			color: $color__brand--second;
		}
	}
	&__description {
		margin-bottom: 45px;
		@include media("<=sm") {
			& br {
				display: none;
			}
		}
	}
}