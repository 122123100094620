//	--------------------
//	General:
//	--------------------

body {
	font: {
		family: $font__brand--text;
		size: $font__text--size;
		weight: $font__text--weight
	}
	line-height: $font__text--lh;
	color: $color__default--black;
	background-color: $color__default--white;
}

p {
	padding: 0;
	margin: 0;
}

a {
	color: $color__default--black;
	text-decoration: none;
}

.opened-menu {
	// position: fixed;
	// left: 0;
	// top: 0;
	// width: 100%;
	overflow-y:scroll;
	flex: 1 0 100%;
}

.container {
	margin: 0 auto;
	width: 100%;
	max-width: 1190px;
	@include media("<=1300px") {
		max-width: 1165px;
	}
	@include media("<=1235px") {
		max-width: 1024px;
	}
	@include media("<=1100px") {
		max-width: 960px;
	}
	@include media("<=1075px") {
		max-width: 870px;
	}
	// @include media("<=1035px") {
	// 	max-width: 870px;
	// }
	@include media("<=lg") {
		max-width: 760px;
	}
	@include media("<=830px") {
		max-width: 690px;
	}
	@include media("<=md") {
		max-width: 100%;
	}
}