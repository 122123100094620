//	--------------------
//	Homepage - About:
//	--------------------

.about {
	background: linear-gradient(to bottom, $color__default--white 145px,#E5E5E5 145px);
	@include media("<=1235px") {
		background: linear-gradient(to bottom, $color__default--white 243px,#E5E5E5 243px);
	}
	&__container {
		padding-left: 80px;
		position: relative;
		border-left: 1px solid $color__default--black;
		&::before {
			content: "Menschen";
			position: absolute;
			top: calc(50% + 90px);
			left: -57px;
			font: {
				size: $font__info-text--size;
				weight: $font__info-text--weight;
			}
			text-transform: uppercase;
			transform: rotate(-90deg);
		}
		@include media("<=1235px") {
			text-align: right;
		}
		@include media("<=md") {
			padding: 0;
			border-left: none;
		}
	}
	&__heading {
		padding-right: 45px;
		padding-bottom: 30px;
		position: absolute;
		top: 185px;
		right: -35px;
		font: {
			size: 70px;
			weight: 300;
		}
		line-height: 78px;
		text-transform: uppercase;
		border-bottom: 7px solid #000000;
		z-index: 1;
		@include media("<=1235px") {
			position: static;
			display: inline-block;
			margin-bottom: 50px;
			text-align: left;
		}
		@include media("<=1024px") {
			padding: 0 30px 30px;
			margin: 0 30px 30px;
			display: block;
			text-align: center;
			& br {
				display: none;
			}
			font-size: 60px;
			line-height: 60px;
		}
		@include media("<=800px") {
			font-size: 48px;
			line-height: 48px;
		}
		@include media("<=320px") {
			padding: 30px 0;
			font-size: 48px;
			line-height: 48px;
		}
	}
	&__image {
		display: block;
		@include media("<=lg") {
			max-width: 100%;
		}
	}
	&__content {
		padding: 55px 0 90px 100px;
		max-width: 835px;
		display: flex;
		justify-content: space-between;
		@include media("<=1075px") {
			padding-left: 0;
		}
		@include media("<=md") {
			padding: 30px;
		}
		@include media("<=640px") {
			flex-wrap: wrap;
		}
	}
	&__text {
		max-width: 435px;
		font-size: 18px;
		line-height: 24px;
		@include media("<=1235px") {
			text-align: left;
		}
		@include media("<=640px") {
			flex: 1 0 100%;
			margin-bottom: 30px;
			max-width: 100%;
		}
	}
	&__button {
		& .button {
			margin-top: 5px;
			margin-left: 100px;
			@include media("<=lg") {
				margin-left: 0;
			}
		}
	}
}