//	--------------------
//	Typography:
//	--------------------

$font__text--size: 18px;
$font__text--weight: 400;
$font__text--lh: 24px;

//	--------------------
//	Custom typography:
//	--------------------

//	Projects headings:
$font__projects-title--size: 24px;
$font__projects-title--weight: 700;
$font__projects-title--lh: 24px;

$font__info-text--size: 12px;
$font__info-text--weight: $font__projects-title--weight;
$font__info-text--lh: 16px;

$font__projects-subtitle--size: 18px;
$font__projects-subtitle--weight: 600;
$font__projects-subtitle--lh: 18px;

//	Projects team:
$font__projects-team--size: 36px;
$font__projects-team--weight: $font__projects-subtitle--weight;
$font__projects-team--lh: 60px;

//	Projects quote:
$font__projects-quote--size: 56px;
$font__projects-quote--weight: 400;
$font__projects-quote--lh: 70px;

//	Filters:
$font__filters-title--size: 17px;
$font__filters-title--weight: 700;
$font__filters-title--lh: 24px;

$font__filters-link--size: 24px;
$font__filters-link--weight: $font__text--weight;
$font__filters-link--lh: 38px;

//	Buttons:
$font__button-text--size: 13px;
$font__button-text--weight: 700;
$font__button-text--lh: 13px;

//	Footer:
$font__footer-text--size: 16px;
$font__footer-text--weight: 400;
$font__footer-text--lh: 30px;

$font__footer-link--size: 17px;
$font__footer-link--weight: $font__footer-text--weight;
$font__footer-link--lh: 24px;