//	--------------------
//	Footer:
//	--------------------

.footer {
	background-color: $color__brand--third;
	&__container {
		padding: 65px 0 29px;
		position: relative;
		z-index: 999;
		display: flex;
		flex-wrap: wrap;
		@include media("<=md") {
			padding: 65px 15px 29px;
		}
	}
	&__logo {
		margin-bottom: 33px;
		flex: 1 0 100%;
		& .logo__image {
			& path:nth-child(1) {
				fill: $color__default--white;
			}
			& path:nth-child(2) {
				fill: $color__brand--fifth;
			}
		}
		@include media("<=680px") {
			text-align: center;
		}
	}
	&__column {
		flex: 1 0 33.33%;
		color: $color__default--white;
		&--copy {
			flex: 1 0 100%;
		}
		@include media("<=680px") {
			flex: 1 0 100%;
			text-align: center;
		}
	}
	&__address {
		margin-bottom: 35px;
		font: {
			size: $font__footer-text--size;
			weight: $font__footer-text--weight;
			style: normal;
		}
		line-height: $font__footer-text--lh;
	}
	&__links {
		padding: 0 18px;
	}
	&__menu {
		padding: 0 25px;
	}
	&__item {
		margin-bottom: 6px;
	}
	&__link {
		color: $color__default--white;
		font: {
			size: $font__footer-link--size;
			weight: $font__footer-link--weight;
		}
		line-height: $font__footer-link--lh;
		text-decoration: none;
		&--fb {
			margin-top: 25px;
			width: 40px;
			height: 40px;
			background-image: icon("fb.png");
			background-repeat: no-repeat;
			background-position: 3px center;
			display: inline-block;
			border: 1px solid $color__default--white;
			border-radius: 50%;
		}
	}
	&__copy {
		position: relative;
		top: -46px;
		display: inline-block;
		@include media("<=680px") {
			margin-top: 15px;
			position: static;
		}
	}
	&__back {
		width: 27px;
		height: 14px;
		position: absolute;
		top: 155px;
		right: 20px;
		background: {
			image: icon("arrow.svg");
			repeat: no-repeat;
			position: center center;
		}
	}
}