//	--------------------
//	Steps - Crew:
//	--------------------

.crew {
	position: relative;
	&__logo {
		@include media(">=1600px") {
			&::after {
				content: brand("logo.svg");
				width: 590px;
				height: auto;
				position: absolute;
				left: 0;
				top: calc(30% + 45px);
				transform: rotate(90deg);
				transform-origin: 123px 0;
			}
		}
	}
	&__container {
		padding: 0 0 0 215px;
		position: relative;
		border-left: 1px solid $color__default--black;
		@include media("<=1280px") {
			padding-left: 80px;
		}
		@include media("<=md") {
			padding: 0 30px;
		}
	}
	&__content {
		padding-top: 135px;
		padding-bottom: 38px;
		position: relative;
		border-left: 3px dotted #000000;
		&::before {
			padding: 22px;
			width: 104px;
			height: 104px;
			position: absolute;
			top: 202px;
			left: -57px;
			background: $color__default--white;
			font: {
				size: 54px;
				weight: 600;
			}
			text-align: center;
			border: 7px solid #000000;
			border-radius: 50%;
			z-index: 10;
			counter-increment: step;
			content: counter(step);
			@include media("<=1024px") {
				top: 60px;
				left: -52px;
			}
			@include media("<=800px") {
				content: none;
			}
		}
		@include media("<=1280px") {
			text-align: right;
		}
		@include media("<=1024px") {
			padding-top: 60px;
		}
		@include media("<=800px") {
			padding-top: 0;
			border-left: none;
		}
	}
	&__heading {
		padding: 45px 0 35px;
		position: absolute;
		top: 307px;
		left: -35px;
		background: $color__default--white;
		font: {
			size: 34px;
			weight: 300;
		}
		line-height: 44px;
		text-transform: uppercase;
		@include media("<=1820px") {
			left: -60px;
		}
		@include media("<=1600px") {
			left: -105px;
		}
		@include media("<=1280px") {
			text-align: left;
		}
		@include media("<=1024px") {
			padding: 30px 0;
			top: 187px;
			left: -45px;
		}
		@include media("<=800px") {
			position: static;
		}
		@include media("<=md") {
			text-align: center;
			& br {
				display: none;
			}
		}
		@include media("<=360px") {
			font-size: 28px;
			line-height: 30px;
		}
		@include media("<=360px") {
			font-size: 24px;
		}
	}
	&__boxes {
		display: flex;
		flex-wrap: wrap;
		@include media("<=1280px") {
			text-align: left;
		}
	}
	&__box {
		flex: 1 0 50%;
		&--left {
			flex: 1 0 60%;
			@include media("<=800px") {
				order: 1;
			}
		}
		&--right {
			flex: 1 0 40%;
			@include media("<=1024px") {
				flex: 1 0 100%;
			}
		}
	}
	&__photo {
		position: relative;
		left: 435px;
		width: 100%;
		max-width: 850px;
		display: block;
		@include media("<=1820px") {
			left: 320px;
		}
		@include media("<=1600px") {
			left: 250px;
		}
		@include media("<=1550px") {
			max-width: 800px;
		}
		@include media("<=1280px") {
			position: static;
			display: inline-block;
			max-width: 675px;
		}
		@include media("<=1024px") {
			max-width: 490px;
		}
		@include media("<=lg") {
			max-width: 360px;
		}
		@include media("<=800px") {
			max-width: 100%;
		}
		&--alt {
			top: -142px;
			left: 64px;
			max-width: 515px;
			border: 8px solid $color__default--white;
			@include media("<=1820px") {
				left: 60px;
			}
			@include media("<=1600px") {
				left: 60px;
			}
			@include media("<=1280px") {
				position: relative;
				top: -50px;
			}
			@include media("<=1024px") {
				top: -30px;
			}
			@include media("<=lg") {
				top: 45px;
			}
			@include media("<=800px") {
				max-width: 100%;
				position: static;
				border: none;
			}
		}
	}
	&__text {
		padding-top: 80px;
		padding-left: 54px;
		@include media("<=1440px") {
			padding-top: 30px;
			padding-left: 75px;
		}
		@include media("<=1280px") {
			padding-top: 45px;
			padding-left: 75px;
		}
		@include media("<=1024px") {
			padding-top: 0;
		}
		@include media("<=lg") {
			padding-top: 60px;
		}
		@include media("<=800px") {
			padding-top: 30px;
			padding-bottom: 30px;
			padding-left: 0;
		}
	}
}