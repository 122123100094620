//	--------------------
//	Steps - Bar:
//	--------------------

.bar {
	background: #E5E5E5;
	&__container {
		padding: 103px 0px 93px 213px;
		position: relative;
		border-left: 1px solid $color__default--black;
		@include media("<=1280px") {
			padding-left: 80px;
		}
		@include media("<=lg") {
			// padding-left: 110px;
		}
		@include media("<=800px") {
			padding: 60px 30px;
		}
	}
	&__content {
		&::before {
			content: "";
			width: 3px;
			height: 102px;
			position: absolute;
			top: 0px;
			left: 216px;
			border-left: 3px dotted #000000;
			@include media("<=1280px") {
				left: 84px;
			}
			@include media("<=lg") {
				
			}
			@include media("<=800px") {
				content: none;
			}
		}
		&::after {
			content: "";
			width: 3px;
			height: 102px;
			position: absolute;
			bottom: 0;
			right: 0;
			border-left: 3px dotted #000000;
			@include media("<=lg") {
				// right: 3px;
			}
			@include media("<=800px") {
				content: none;
			}
		}
	}
	&__box {
		position: relative;
		border-left: 10px solid $color__default--black;
		border-right: 10px solid $color__default--black;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 45px;
			height: 10px;
			background: $color__default--black;
		}
		&::after {
			content: "";
			position: absolute;
			bottom: -7px;
			left: -10px;
			width: 55px;
			height: 10px;
			background: $color__default--black;
		}
	}
	&__text {
		padding: 18px 0 36px;
		font: {
			size: 70px;
			weight: 300;
		}
		line-height: 70px;
		text-align: center;
		text-transform: uppercase;
		@include media("<=800px") {
			font-size: 36px;
			line-height: 36px;
		}
		@include media("<=480px") {
			padding: 30px 0;
		}
		@include media("<=375px") {
			font-size: 32px;
		}
		@include media("<=320px") {
			font-size: 28px;
		}
	}
	&__highlight {
		color: $color__brand--second;
	}
}