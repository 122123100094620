//	--------------------
//	Homepage - Process:
//	--------------------

.process {
	min-height: 665px;
	background: {
		image: pic("homepage-process-bg.jpg");
		repeat: no-repeat;
		position: right center;
	}
	@include media("<=md") {
			min-height: 100%;
		}
	&__container {
		padding: 200px 0 205px 250px;
		position: relative;
		border-left: 1px solid $color__default--black;
		&::before {
			content: "Prozesse";
			position: absolute;
			top: calc(40% + 28px);
			left: -55px;
			font: {
				size: $font__info-text--size;
				weight: $font__info-text--weight;
			}
			text-transform: uppercase;
			transform: rotate(-90deg);
		}
		@include media("<=lg") {
			padding-left: 150px;
		}
		@include media("<=md") {
			padding: 100px 0;
			text-align: center;
			border-left: none;
		}
	}
	&__heading {
		color: #666666;
		font: {
			size: 118px;
			weight: 300;
		}
		line-height: 152px;
		@include media("<=lg") {
			font-size: 96px;
			line-height: 125px;
		}
		@include media("<=md") {
			font-size: 84px;
			line-height: 84px;
		}
		@include media("<=360px") {
			font-size: 60px;
			line-height: 60px;
		}
		@include media("<=320px") {
			font-size: 48px;
			line-height: 48px;
		}
	}
	&__spacer {
		display: inline-block;
		width: 335px;
		@include media("<=1235px") {
			display: none;
		}
	}
	&__button {
		& .button {
			margin-top: 120px;
			margin-left: 65px;
			@include media("<=lg") {
				margin-top: 60px;
				margin-left: 0;
			}
		}
	}
}