//	--------------------
//	Homepage - Work:
//	--------------------

.team {
	margin: 0 auto;
	max-width: 1907px;
	position: relative;
	&__container {
		padding: 0 0 0 110px;
		position: relative;
		border-left: 1px solid $color__default--black;
		&::before {
			content: "Hinter den kulissen";
			position: absolute;
			top: calc(20% + 17px);
			left: -91px;
			font: {
				size: $font__info-text--size;
				weight: $font__info-text--weight;
			}
			text-transform: uppercase;
			transform: rotate(-90deg);
			@include media("<=1075px") {
				content: none;
			}
		}
		&--first {
			&::before {
				content: none;
			}
			@include media("<=1280px") {
				text-align: right;
				padding-bottom: 30px;
			}
		}
		&--slider {
			min-height: 750px;
			border: none;
			&::before {
				content: none;
			}
			@include media("<=1810px") {
				min-height: 680px;
			}
			@include media("<=1600px") {
				min-height: 655px;
			}
			@include media("<=1024px") {
				padding-left: 0;
				min-height: auto;
			}
		}
		&--last {
			margin-bottom: 270px;
			&::after {
				content: "Bekommen wir gerne";
				padding-left: 70px;
				max-height: 16px;
				position: absolute;
				left: -104px;
				bottom: 37px;
				background: {
					color: $color__default--white;
					image: icon("envelope.svg");
					repeat: no-repeat;
					position: 30px 0;
				}
				font: {
					size: $font__info-text--size;
					weight: $font__info-text--weight;
				}
				line-height: $font__info-text--lh;
				text-transform: uppercase;
				transform: rotate(90deg);
				@include media("<=md") {
					content: none;
				}
			}
			@include media("<=800px") {
				padding: 75px 0 60px 80px;
				margin-bottom: 100px;
			}
		}
		@include media("<=md") {
			padding: 0;
			border-left: none;
		}
	}
	&__heading {
		padding-right: 45px;
		padding-bottom: 25px;
		position: absolute;
		top: 45px;
		right: -50px;
		font: {
			size: 70px;
			weight: 300;
		}
		line-height: 78px;
		text-transform: uppercase;
		border-bottom: 7px solid #000000;
		z-index: 1;
		@include media("<=1280px") {
			position: static;
			display: inline-block;
			margin-bottom: 50px;
			text-align: left;
		}
		@include media("<=1024px") {
			padding: 30px;
			margin: 0 30px;
			font-size: 60px;
			display: block;
			text-align: center;
			& br {
				display: none;
			}
		}
		@include media("<=800px") {
			margin-top: 0;
			font-size: 48px;
			line-height: 48px;
		}
		@include media("<=480px") {
			padding: 30px 0;
			font-size: 42px;
			line-height: 42px;
		}
	}
	&__slider {
		position: absolute;
		left: 0;
		max-width: 1315px;
		@include media("<=1280px") {
			max-width: 1150px;
		}
		@include media("<=1024px") {
			position: static;
			max-width: 100%;
		}
	}
	&__content {
		display: flex;
		@include media("<=md") {
			padding: 0 30px;
		}
	}
	&__box {
		&--first {
			// order: 1;
		}
		&--last {
			height: 450px;
			@include media("<=1075px") {
				display: none;
			}
		}
	}
	&__photo {
		position: relative;
		top: -280px;
		left: 10px;
		border: 7px solid $color__default--white;
		display: block;
		@include media("<=1280px") {
			top: 80px;
		}
	}
	&__text {
		padding-top: 97px;
		padding-left: 103px;
		@include media("<=1370px") {
			// padding-top: 0;
		}
		@include media("<=1280px") {
			padding-top: 80px;
		}
		@include media("<=1075px") {
			padding-left: 0;
		}
		@include media("<=lg") {
			padding-top: 0;
		}
		@include media("<=lg") {
			padding-top: 80px;
		}
		@include media("<=800px") {
			padding-top: 0;
		}
		@include media("<=md") {
			padding-top: 80px;
		}
		@include media("<=640px") {
			padding-top: 80px;
		}
	}
	&__button {
		position: relative;
		& .button {
			padding: 23px 28px;
			margin-top: 81px;
			margin-left: 103px;
			
			position: absolute;
			top: 80px;
			left: 103px;
			margin: 0;
			@include media("<=1075px") {
				position: static;
				margin-top: 30px;
				margin-left: 0;
			}
		}
	}
}

.slider {
	&__body {
		&--team {
			left: 60px;
			@include media("<=1810px") {
				left: 60px;
			}
			@include media("<=1490px") {
				left: 60px;
			}
			@include media("<=1375px") {
				left: 30px;
			}
			@include media("<=1280px") {
				left: 0;
			}
			@include media("<=1024px") {
				margin-bottom: 0 !important;
			}
			& .slick-dots {
				bottom: -54px;
				left: -121px;
				@include media("<=1024px") {
					left: 0;
				}
			}
			@include media("<=800px") {
				margin-bottom: 0 !important;
			}
		}
	}
	&__image {
		width: 100%;
		@include media("<=1810px") {
			max-width: 1150px;
		}
		@include media("<=1024px") {
			max-width: 1024px;
		}
	}
}