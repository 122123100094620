//	--------------------
//	Projects:
//	--------------------

.projects {
	&__container {
		padding: 0 0 50px 110px;
		margin-bottom: 175px;
		width: 100%;
		position: relative;
		border-left: 1px solid $color__default--black;
		&::before {
			content: "Noch mehr projekte";
			position: absolute;
			left: -96px;
			top: 70%;
			font: {
				size: $font__info-text--size;
				weight: $font__info-text--weight;
			}
			text-transform: uppercase;
			transform: rotate(-90deg);
		}
		&::after {
			content: "Bekommen wir gerne";
			padding-left: 70px;
			max-height: 16px;
			position: absolute;
			left: -104px;
			bottom: 37px;
			background: {
				color: $color__default--white;
				image: icon("envelope.svg");
				repeat: no-repeat;
				position: 30px 0;
			}
			font: {
				size: $font__info-text--size;
				weight: $font__info-text--weight;
			}
			line-height: $font__info-text--lh;
			text-transform: uppercase;
			transform: rotate(90deg);
		}
		@include media("<=1300px") {
			padding: 0 0 50px 50px;
		}
		@include media("<=md") {
			padding: 0 15px 50px;
			margin-bottom: 0;
			border: none;
			&::before,
			&::after {
				content: none;
			}
		}
	}
	&__title {
		position: absolute;
		left: -60px;
		top: 430px;
		font: {
			size: $font__info-text--size;
			weight: $font__info-text--weight;
		}
		text-transform: uppercase;
		transform: rotate(-90deg);
		@include media("<=md") {
			margin-bottom: 30px;
			position: static;
			font-size: $font__projects-title--size;
			line-height: $font__projects-title--lh;
			text-align: center;
			transform: rotate(0);
		}
	}
	&__list {
		// margin: 0 -15px 15px;
		margin: 0 -15px -5px;
		&::after {
			content: "";
			display: block;
			clear: both;
		}
	}
	&__item {
		padding: 0 16px 40px;
		width: 33.33%;
		float: left;
		@include media("<=800px") {
			padding-bottom: 30px;
			width: 50%;
		}
		@include media("<=sm") {
			width: 100%;
		}
		&--default {
			width: 33.33%;
			@include media("<=800px") {
				width: 50%;
			}
			@include media("<=sm") {
				width: 100%;
			}
		}
		&--stamp {
			@include media("<=lg") {
				width: 100%;
			}
			@include media("<=800px") {
				width: 100%;
			}
		}
		&--13 {
			width: 33.33%;
			@include media("<=800px") {
				width: 50%;
			}
			@include media("<=sm") {
				width: 100%;
			}
		}
		&--23 {
			width: 66.66%;
			@include media("<=800px") {
				width: 50%;
			}
			@include media("<=sm") {
				width: 100%;
			}
		}
		&--33 {
			width: 50%;
			@include media("<=sm") {
				width: 100%;
			}
		}
	}
	&__subtitle {
		margin: 14px 0 21px;
		font: {
			size: $font__projects-subtitle--size;
			weight: $font__projects-subtitle--weight;
		}
		line-height: $font__projects-subtitle--lh;
		text-align: right;
		&--team {
			margin: 0 0 42px;
			color: $color__default--white;
			font: {
				size: $font__projects-team--size;
				weight: $font__projects-team--weight;
			}
			line-height: $font__projects-team--lh;
			text-transform: uppercase;
			@include media("<=830px") {
				font-size: 32px;
			}
			@include media("<=800px") {
				text-align: center;
			}
		}
	}
	&__description {
		padding: 84px 90px 84px 42px;
		min-height: 580px;
		color: $color__default--white;
		@include media("<=1075px") {
			padding: 60px 42px;
			min-height: 360px;
		}
		@include media("<=sm") {
			min-height: auto;
		}
	}
	&__image {
		width: 100%;
		max-width: 100%;
		display: block;
	}
	&__content {
		border-top: 2px solid $color__default--black;
		&--team {
			padding: 25px 35px 100px 125px;
			background: pic('6.png') no-repeat;
			text-align: right;
			border: none;
			@include media("<=1235px") {
				padding: 25px 35px 95px 105px;
			}
			@include media("<=1100px") {
				padding: 25px 35px 95px 35px;
			}
			@include media("<=lg") {
				padding: 25px 20px 95px;
			}
			@include media("<=800px") {
				padding: 45px 30px;
				background: $color__brand--second;
				text-align: center;
			}
		}
		&--alt {
			background: {
				image: pic('10.jpg');
				repeat: no-repeat;
				position-y: 50px;
				size: cover;
			} 
			@include media("<=800px") {
				// background-size: cover;
			}
		}
		&--quote {
			color: $color__brand--fourth;
			font: {
				size: $font__projects-quote--size;
				weight: $font__projects-quote--weight;
			}
			line-height: $font__projects-quote--lh;
			border: none;
			@include media("<=1100px") {
				font-size: 54px;
			}
			@include media("<=1075px") {
				font-size: 48px;
			}
			@include media("<=lg") {
				font-size: 40px;
			}
			@include media("<=800px") {
				font-size: 38px;
				text-align: center;
			}
			@include media("<=640px") {
				font-size: 48px;
			}
			@include media("<=sm") {
				text-align: center;
			}
		}
	}
	&__filters {
		padding: 29px 40px 41px 32px;
		border: 2px solid $color__default--black;
		border-top: none;
	}
	&__link {
		text-decoration: none;
		&--alt {
			color: $color__default--white;
		}
	}
	&__button {
		text-align: center;
	}
}

.filters {
	&__item {
		@include media("<=lg") {
			width: 50%;
			display: inline-block;
		}
		@include media("<=md") {
			width: 100%;
			text-align: center;
		}
		&--all {
			text-align: right;
			@include media("<=lg") {
				width: 100%;
				text-align: center;
				display: block;
			}
		}
	}
	&__link {
		margin-bottom: 38px;
		color: $color__default--black;
		font: {
			size: $font__filters-link--size;
			weight: $font__filters-link--weight;
		}
		line-height: $font__filters-link--lh;
		text-decoration: none;
		display: inline-block;
		&:hover,
		&--active {
			color: $color__brand--second;
		}
		&--title {
			margin-bottom: 46px;
			font: {
				size: $font__filters-title--size;
				weight: $font__filters-title--weight;
			}
			line-height: $font__filters-title--size;
			text-align: right;
		}
		&--last {
			margin-bottom: 0;
		}
		@include media("<=1075px") {
			font-size: 20px;
		}
		@include media("<=lg") {
			margin-bottom: 15px;
			font-size: 18px;
		}
	}
}

.button {
	padding: 23px 29px;
	color: $color__default--white;
	background: $color__brand--third;
	font: {
		size: $font__button-text--size;
		weight: $font__button-text--weight;
	}
	line-height: $font__button-text--lh;
	text-transform: uppercase;
	display: inline-block;
	&--more {
		margin: 0 auto;
		width: 100%;
		// max-width: 320px;
		max-width: 336px;
		text-align: center;
		// display: block;
	}
}