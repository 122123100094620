//	--------------------
//	Steps - Quote:
//	--------------------

.quote {
	background: #E5E5E5;
	&__container {
		padding: 76px 0px 70px 173px;
		position: relative;
		border-left: 1px solid $color__default--black;
		@include media("<=1024px") {
			padding-left: 120px;
		}
		@include media("<=md") {
			padding: 60px;
		}
		@include media("<=360px") {
			padding: 30px;
		}
	}
	&__content {
		&::before {
			content: "";
			width: 3px;
			height: 140px;
			position: absolute;
			bottom: -140px;
			left: 215px;
			border-left: 3px dotted #000000;
			@include media("<=1820px") {
				height: 83px;
				bottom: -83px;
			}
			@include media("<=1280px") {
				height: 63px;
				bottom: -63px;
				left: 80px;
			}
			@include media("<=1200px") {
				height: 40px;
				bottom: -42px;
			}
			@include media("<=800px") {
				content: none;
			}
		}
		&::after {
			content: "";
			width: 215px;
			height: 3px;
			position: absolute;
			bottom: -140px;
			left: 217px;
			border-bottom: 3px dotted #000000;
			@include media("<=1820px") {
				bottom: -80px
			}
			@include media("<=1280px") {
				width: 100px;
				left: 80px;
			}
			@include media("<=800px") {
				content: none;
			}
		}
	}
	&__box {
		position: relative;
	}
	&__text {
		padding: 18px 0 36px;
		font: {
			size: 52px;
			weight: 300;
		}
		line-height: 60px;
		@include media("<=lg") {
			font-size: 48px;
		}
		@include media("<=800px") {
			font-size: 42px;
		}
		@include media("<=360px") {
			font-size: 32px;
			line-height: 48px;
		}
	}
	&__highlight {
		position: absolute;
		right: 0;
		bottom: 32px;
		color: $color__brand--second;
		font: {
			size: 18px;
			weight: 600;
		}
		line-height: 24px;
		@include media("<=md") {
			padding-top: 15px;
			position: static;
			text-align: right;
			display: block;
		}
	}
}