//	--------------------
//	Fonts:
//	--------------------

$font__default--text: Arial, sans-serif;
$font__brand--text: 'Nunito', Arial, sans-serif;

//	--------------------
//	Fonts sources:
//	--------------------

@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700,800&subset=latin-ext');