@charset 'utf-8';

// ----------------------------------------
//         STYLES COMPILED WITH SASS
//      Naming conceptions of the files:
//            BEM CSS METHODOLOGY:
//            http://en.bem.info/
// ----------------------------------------
//
// @devs:
// 		Please keep current comments convention
// 		/* CSS comments */ only for clients
//

//	--------------------------------	Vendors
@import '__vendors/normalize';
@import '__vendors/include-media';

//	--------------------------------	Helpers
@import '__helpers/fonts';
@import '__helpers/typography';
@import '__helpers/colors';
@import '__helpers/mixins';
@import '__helpers/reset';
@import '__helpers/breakpoints';
@import '__helpers/general';

//	-----------------------------	Components
@import '__components/header';
@import '__components/footer';
//	-----------------------------	Views components
@import '__components/__homepage/about';
@import '__components/__homepage/process';
@import '__components/__homepage/news';
@import '__components/__homepage/work';
@import '__components/__homepage/jobs';
@import '__components/__homepage/team';
@import '__components/__steps/baner';
@import '__components/__steps/identity';
@import '__components/__steps/bar';
@import '__components/__steps/planning';
@import '__components/__steps/crew';
@import '__components/__steps/quote';
@import '__components/__steps/creation';
@import '__components/__steps/interaction';
@import '__components/__projects/slider';
@import '__components/__projects/people';
@import '__components/__projects/description';
@import '__components/__projects/all-projects';