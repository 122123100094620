//	--------------------
//	SingleProject - Carousel:
//	--------------------

.header--carousel::after {
	content: none !important;
}

.carousel {
	&__body {
		margin-bottom: 40px;
		left: 0;
		& .slick-dots {
			width: 25%;
			left: calc(50% + 12px);
			bottom: -50px;
			@include media("<=xl") {
				width: 100%;
				left: auto;
			}
		}
	}
	&__item {
		background: {
			repeat: no-repeat;
			position: center center;
			size: cover;
		}
	}
	&__container {
		max-width: 1350px;
		text-align: right;
		@include media("<=640px") {
			min-height: 300px;
		}
	}
	&__info {
		padding: 92px 68px 45px 38px;
		margin-top: 255px;
		background: rgba(255,255,255,.9);
		display: inline-block;
		text-align: left;
		@include media("<=1280px") {
			margin-top: 90px;
		}
		@include media("<=lg") {
			padding-top: 60px;
			padding-right: 38px;
		}
		@include media("<=640px") {
			// display: none;
			margin-top: 0;
			display: block;
			width: 100%;
			text-align: center;
		}
	}
	&__table {
		@include media("<=640px") {
			width: 100%;
			text-align: left;
			& td {
				padding-left: 30px;
				&:first-child {
					padding-left: 0;
					width: 160px;
					text-align: right;
				}
			}
		}
	}
	&__heading {
		padding-right: 18px;
		padding-bottom: 30px;
		margin: 0 0 95px;
		font: {
			size: 70px;
			weight: 300;
		}
		line-height: 78px;
		text-transform: uppercase;
		border-bottom: 6px solid $color__default--black;
		@include media("<=1280px") {
			font-size: 60px;
			line-height: 60px;
		}
		@include media("<=lg") {
			margin-bottom: 60px;
			font-size: 48px;
			line-height: 48px;
		}
		@include media("<=640px") {
			& br {
				display: none;
			} 
		}
	}
}