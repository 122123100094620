//	--------------------
//	Steps - Baner:
//	--------------------

.baner {
	background: {
		image: pic("steps-baner-photo.jpg");
		repeat: no-repeat;
		position: center center;
		size: contain;
	} 
	@include media("<=lg") {
		background-size: cover;
	}
	&__container {
		padding-left: 80px;
		position: relative;
		min-height: 860px;
		@include media("<=1600px") {
			min-height: 710px;
		}
		@include media("<=1375px") {
			min-height: 600px;
		}
		@include media("<=1280px") {
			min-height: 550px;
		}
		@include media("<=1024px") {
			min-height: 450px;
		}
		@include media("<=md") {
			padding: 0 30px;
			margin-top: 120px;
		}
		@include media("<=360px") {
			margin-top: 150px;
		}
	}
	&__heading {
		padding-right: 45px;
		padding-bottom: 30px;
		position: absolute;
		top: 190px;
		right: -50px;
		font: {
			size: 70px;
			weight: 300;
		}
		line-height: 78px;
		text-transform: uppercase;
		border-bottom: 7px solid #000000;
		z-index: 1;
		@include media("<=1375px") {
			right: 0;
		}
		@include media("<=1024px") {
			top: 50px;
		}
		@include media("<=md") {
			padding: 30px;
			position: relative;
			top: -150px;
			right: 0;
			font-size: 48px;
			line-height: 48px;
			text-align: center;
			& br {
				display: none;
			}
		}
		@include media("<=570px") {
			padding: 30px 0;
			font-size: 42px;
			line-height: 42px;
		}
		@include media("<=570px") {
			top: -180px;
		}
	}
}