//	--------------------
//	Homepage - Work:
//	--------------------

.work {
	&__container {
		padding: 325px 0 0 110px;
		background: {
			image: pic("homepage-work-bg.jpg");
			repeat: no-repeat;
			position: center top;
		}
		position: relative;
		border-left: 1px solid $color__default--black;
		&::before {
			content: "Projekte";
			position: absolute;
			top: calc(50% + 63px);
			left: -57px;
			font: {
				size: $font__info-text--size;
				weight: $font__info-text--weight;
			}
			text-transform: uppercase;
			transform: rotate(-90deg);
		}
		@include media("<=1280px") {
			padding-top: 100px;
			background-size: cover;
		}
		@include media("<=md") {
			padding: 0;
			border-left: none;
		}
	}
	&__heading {
		padding-right: 45px;
		padding-bottom: 30px;
		position: absolute;
		top: 315px;
		right: -15px;
		font: {
			size: 70px;
			weight: 300;
		}
		line-height: 78px;
		text-transform: uppercase;
		border-bottom: 7px solid #000000;
		z-index: 1;
		@include media("<=1280px") {
			top: 90px;
		}
		@include media("<=1024px") {
			padding: 30px;
			margin: 30px;
			position: static;
			font-size: 60px;
			line-height: 60px;
			display: block;
			text-align: center;
			& br {
				display: none;
			}
		}
		@include media("<=800px") {
			font-size: 48px;
			line-height: 48px;
		}
		@include media("<=360px") {
			padding: 30px 0;
		}
	}
	&__slider {
		margin-top: 235px;
		@include media("<=1024px") {
			margin-top: 0;
		}
	}
	&__content {
		display: flex;
		@include media("<=1075px") {
			flex-wrap: wrap;
		}
		@include media("<=md") {
			padding: 0 30px 30px;
		}
	}
	&__box {
		&--first {
			order: 1;
			@include media("<=1075px") {
				order: 0;
			}
		}
		&--last {
			@include media("<=1075px") {
				display: none;
			}
		}
		@include media("<=1075px") {
			flex: 1 0 100%;
		}
	}
	&__photo {
		position: relative;
		top: -230px;
		left: 140px;
		border: 7px solid $color__default--white;
		display: block;
		@include media("<=1810px") {
			left: 0;
		}
		@include media("<=1600px") {
			top: 50px;
		}
	}
	&__text {
		padding-top: 90px;
		padding-left: 200px;
		@include media("<=1810px") {
			padding-left: 100px;
		}
		@include media("<=1640px") {
			padding-top: 50px;
		}
		@include media("<=1075px") {
			padding-left: 0;
		}
		@include media("<=md") {
			padding-top: 30px;
		}
	}
	&__button {
		& .button {
			padding: 23px 37px;
			margin-top: 55px;
			margin-left: 200px;
			@include media("<=1810px") {
				margin-left: 100px;
			}
			@include media("<=1075px") {
				margin-left: 0;
			}
			@include media("<=md") {
				margin-top: 20px;
			}
		}
	}
}

.slider {
	&__body {
		left: 305px;
		@include media("<=1810px") {
			left: 150px;
		}
		@include media("<=1640px") {
			left: 0;
		}
	}
	&__image {
		display: block;
	}
}

.slick-dots {
	bottom: -40px;
	left: -75px;
	z-index: 100;
	@include media("<=1810px") {
		left: 75px;
	}
	@include media("<=1490px") {
		left: 0;
	}
	& li {
		margin: 0;
		& button::before {
			font-size: 12px;
		}
	}
}