//	--------------------
//	Steps - Identity:
//	--------------------

.identity {
	counter-reset: step;
	&__container {
		padding: 33px 0 130px 180px;
		position: relative;
		border-left: 1px solid $color__default--black;
		&::before {
			content: "Prozesse";
			position: absolute;
			top: calc(35% + 28px);
			left: -55px;
			font: {
				size: $font__info-text--size;
				weight: $font__info-text--weight;
			}
			text-transform: uppercase;
			transform: rotate(-90deg);
		}
		@include media("<=1280px") {
			padding-left: 80px;
		}
		@include media("<=1024px") {
			// padding-left: 110px;
		}
		@include media("<=800px") {
			padding-top: 15px;
		}
		@include media("<=800px") {
			padding: 0 30px;
		}
		@include media("<=md") {
			border-left: none;
		}
	}
	&__content {
		display: flex;
		&::before {
			padding: 22px;
			width: 104px;
			height: 104px;
			position: absolute;
			bottom: 239px;
			left: 168px;
			background: $color__default--white;
			font: {
				size: 54px;
				weight: 600;
			}
			text-align: center;
			border: 7px solid #000000;
			border-radius: 50%;
			z-index: 10;
			counter-increment: step;
			content: counter(step);
			@include media("<=1280px") {
				left: 35px;
			}
			@include media("<=1024px") {
				bottom: 50px;
			}
			@include media("<=lg") {

			}
			@include media("<=800px") {
				content: none;
			}
			@include media("<=md") {
				content: none;
			}
		}
		&::after {
			content: "";
			width: 3px;
			height: 240px;
			position: absolute;
			bottom: 0px;
			left: 216px;
			border-left: 3px dotted #000000;
			@include media("<=1280px") {
				left: 84px;
			}
			@include media("<=1024px") {
				height: 50px;
			}
			@include media("<=lg") {
				
			}
			@include media("<=800px") {
				content: none;
			}
		}
		@include media("<=md") {
			flex-wrap: wrap;
		}
	}
	&__box {
		flex: 1 0 50%;
		&--first {
			order: 1;
			@include media("<=800px") {
				padding-bottom: 45px;
			}
		}
		@include media("<=md") {
			flex: 1 0 100%;
		}
	}
	&__photo {
		width: 100%;
		max-width: 100%;
		display: block;
	}
	&__heading {
		margin: 85px 0 50px;
		font: {
			size: 34px;
			weight: 300;
		}
		line-height: 44px;
		text-transform: uppercase;
		
		@include media("<=1024px") {
			margin-top: 50px;
		}
		@include media("<=lg") {
			margin: 0 0 30px;
		}
		@include media("<=md") {
			text-align: center;
			& br {
				display: none;
			}
		}
		@include media("<=360px") {
			font-size: 28px;
			line-height: 30px;
		}
		@include media("<=360px") {
			font-size: 24px;
		}

	}
	&__text {
		padding-right: 100px;
		@include media("<=1024px") {
			padding-right: 75px;
		}
		@include media("<=lg") {
			padding-right: 30px;
		}
		@include media("<=md") {
			padding-right: 0;
			margin-bottom: 30px;
		}
	}
	&__button {
		& .button {
			margin-top: 120px;
			margin-left: 65px;
		}
	}
}