//	--------------------
//	Steps - Interaction:
//	--------------------

.interaction {
	&__container {
		padding: 0 35px 0 110px;
		margin-bottom: 160px;
		background: {
			image: pic("steps-interaction-bg.jpg");
			repeat: no-repeat;
			position: center top;
		}
		position: relative;
		border-left: 1px solid $color__default--black;
		&::after {
			content: "Bekommen wir gerne";
			padding-left: 70px;
			max-height: 16px;
			position: absolute;
			left: -104px;
			bottom: 90px;
			background: {
				color: $color__default--white;
				image: icon("envelope.svg");
				repeat: no-repeat;
				position: 30px 0;
			}
			font: {
				size: $font__info-text--size;
				weight: $font__info-text--weight;
			}
			line-height: $font__info-text--lh;
			text-transform: uppercase;
			transform: rotate(90deg);
			@include media("<=md") {
				content: none;
			}
		}
		@include media("<=1280px") {
			padding-left: 80px;
		}
		@include media("<=1024px") {
			margin-bottom: 80px;
		}
		@include media("<=800px") {
			padding-right: 0;
		}
		@include media("<=md") {
			padding: 30px;
			margin-bottom: 0;
			border-left: none;
		}
	}
	&__content {
		padding: 585px 70px 0 373px;
		position: relative;
		border-top: 3px dotted $color__default--black;
		&::before {
			padding: 22px;
			width: 104px;
			height: 104px;
			position: absolute;
			top: 166px;
			right: -45px;
			background: $color__default--white;
			font: {
				size: 54px;
				weight: 600;
			}
			text-align: center;
			border: 7px solid $color__default--black;
			border-radius: 50%;
			z-index: 10;
			counter-increment: step;
			content: counter(step);
			@include media("<=800px") {
				content: none;
			}
		}
		&::after {
			content: "";
			width: 3px;
			height: 170px;
			position: absolute;
			top: 2px;
			right: 0;
			border-right: 3px dotted #000000;
			@include media("<=800px") {
				content: none;
			}
			
		}
		@include media("<=xl") {
			padding: 515px 0 60px 0;
		}
		@include media("<=1024px") {
			padding-top: 430px;
		}
		@include media("<=lg") {
			padding-top: 400px;
		}
		@include media("<=800px") {
			padding-top: 0;
			border-top: none;
		}
	}
	&__photo {
		position: absolute;
		top: -95px;
		left: -410px;
		@include media("<=xl") {
			max-width: 1200px;
		}
		@include media("<=1024px") {
			max-width: 1040px;
		}
		@include media("<=lg") {
			max-width: 925px;
		}
		@include media("<=800px") {
			margin-bottom: 60px;
			width: 100%;
			position: static;
		}
		@include media("<=360px") {
			margin-bottom: 30px;
		}
	}
	&__heading {
		margin: -10px 0 55px;
		font: {
			size: 34px;
			weight: 300;
		}
		line-height: 44px;
		text-transform: uppercase;
		@include media("<=md") {
			text-align: center;
		}
		@include media("<=360px") {
			font-size: 28px;
			line-height: 30px;
		}
		@include media("<=360px") {
			font-size: 24px;
		}	}
	&__text {
		margin-bottom: 25px;
	}
	&__highlight {
		color: $color__brand--second;
	}
}